$primary: #0768fd; 
 $primaryDark: #0557d5; 
 $primaryLight: #ecf3ff; 
 $neptunePrimary: #0768fd; 
 $neptunePrimaryDark: #0557d5; 
 $neptunePrimaryLight: #ecf3ff; 
 $primaryHover: #ecf3ff; 
 $primaryHoverTransparent: rgba(35, 114, 253, 0.08); 
 $primaryHoverDark: #0557d5; 
 $transparentLight: rgba(255, 255, 255, 0.16); 
 $transparent: rgba(255, 255, 255, 0.24); 
 $primaryLightTransparent: rgba(35, 114, 253, 0.08); 
 $neptunePrimaryLightTransparent: rgba(35, 114, 253, 0.08); 
 $white: #ffffff; 
 $neutral1: #f8f9fb; 
 $neutral2: #f2f2f2; 
 $neutral3: #e9e9e9; 
 $neutral4: #d9d9d9; 
 $neutral5: #b5b5b5; 
 $neutral6: #999999; 
 $neutral7: #595959; 
 $neutral8: #444444; 
 $black: #000000; 
 $green: #00c221; 
 $orange: #ff9300; 
 $red: #e20000; 
 $purple: #9e54b0; 
 $blue: #015ff1; 
 $fuchsia: #df216d; 
 $blueDark: #10558a; 
 $dataVizColors: #9e54b0,#015ff1,#ff9300,#df216d,#00c221,#10558a; 
 $primaryLight2: #83b4fe; 
 $primaryDark2: #0555fc; 
 $primaryDivider: #e1edff; 
 $secondary: #0076ae; 
 $secondaryLight: #80bbd7; 
 $secondaryDark: #00639d; 
 $redLight: #f18080; 
 $redDark: #c50404; 
 $blueDark700: #0e4271; 
 $blueDark800: #093561; 
 $themeGrey50: #ffffff; 
 $themeGrey100: #f8f9fb; 
 $themeGrey200: #f2f2f2; 
 $themeGrey300: #e9e9e9; 
 $themeGrey400: #d9d9d9; 
 $themeGrey500: #b5b5b5; 
 $themeGrey600: #999999; 
 $themeGrey700: #595959; 
 $themeGrey800: #444444; 
 $themeGrey900: #000000; 
 $themeGreyA100: #f4f7fd; 
 $themeGreyA200: #dfe3eb; 
 $themeGreyA400: #c8cbd1; 
 $themeGreyA700: #a6aab2; 
 $themeGreycontrastDefaultColor: light; 
 $themeGreyhover: #dddddd; 
 $gradientStart: #250056; 
 $gradientHorizontal: linear-gradient(to right, #250056, #0076ae); 
 $neptuneGradient: linear-gradient(to right, #250056, #0076ae); 
 $utilityNegative: #e20000; 
 $utilityWarning: #ff9300; 
 $utilityPositive: #00c221; 
 $utilityInfo: #0768fd; 
 