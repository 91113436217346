.unauthorizedContainer {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  max-width: 100%;
  max-height: 100vh;
  min-width: 680px;
  overflow: hidden;
  .imageContainer {
    flex: 0 0 50%;
    text-align: right;
    .image {
      height: 100vh;
      width: 57vw;
    }
  }

  .loginContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin: 0% 0% 0% 5%;
  }
}
.container {
  .heading {
    font-weight: bold;
    font-size: 22px;
    max-width: 300px;
    max-height: 24px;
    color: #000000;
  }
  .content {
    margin-top: 25px;
    margin-bottom: 30px;
    font-size: 14px;
    width: 350px;
    height: 12px;
  }
  .email {
    color: #297dfd;
  }
  .returnBtn {
    margin-top: 50%;
  }
  .footer {
    font-size: 10px;
    width: 100%;
    bottom: 15px;
    position: absolute;
    left: 0;
  }
}
