.autocompleteNewOption {
    width: 100%;
    .readOnly {
        color: #999999;
    }
    .addBtn {
        pointer-events: auto;
    }
}

.autocompleteTags {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
    text-indent: 0.5rem;

    span {
        &:after {
            content: ','
        }
        &:last-child:after {
            content: ''
        }
    }
}


// .autoCompleteInputRoot {
//     border: 1px solid #d9d9d9;
//     padding-left: 0.5rem;
//     &:after, &:before {
//         border: none !important;
//     }
//     &:hover {
//         border: 1px solid #0768fd;
//     }

//     .actionSection {
//         // top: calc(50% - 20px);
//         button {
//             background-color: transparent;
//         }
//     }

//     .autoCompleteInput {
//         border: none;
//     }
    
// }

.autoCompletePoper {
    .autoCompletePaper {
        box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.08);

        li {
            position: relative;
            padding-left: 30px;
    
            &[data-focus="true"], &[aria-selected="true"], &[aria-disabled="true"] {
                background-color: transparent;
            }
            &:hover {
                color: #000000;
                background-color: rgba(35, 114, 253, 0.08);
            }
            &[aria-disabled="true"]:hover {
                background-color: transparent;
                
            }
            &[aria-disabled="true"] {
                opacity: 1;
            }
    
            svg {
                position: absolute;
                left: 5px;
                top: 8px;
            }
    
            .listItemLabel {
                color: #444444;
            }
        }
    }
}

.asterik {
    color: #e20000;
    position: relative;
    left: 3px;
    bottom: 2px;
    font-size: 20px;
    font-weight: bold;
}



.validation{
   color:red;
}

.orgValidation{
    color: red;
    position: relative;
    bottom: 90px;
    left: 140px;
    width:120px;
}