@import './apolloVariables.scss';

$bodyColor: #F7F8FC;
$lightBlueBg: #ecf3ff;
$pEReferenceLabel:#a020f0;
$pTReferenceLabel:#3182bd;
$referenceArea:#8ec0a4;
:export{
  pT:$pTReferenceLabel;
  pE:$pEReferenceLabel;
  sweetRegion:$referenceArea;
}