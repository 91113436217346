.training-video {
  .MuiDialogContent-root {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
 
  & > div > div:nth-child(2) > h3 {
    font-size: 1.3rem;
  }

  video {
    margin-bottom: 15px;
  }
}
