.submit-feedback-actions{
  display: flex;
  justify-content: space-between;
  margin-top: 10%;

  .action-buttons{
    Button{
      margin-left: 10px;
    }
  }
}

.submit-feedback [class^='MuiDialogActions-root'] {
  display: none;
}

.submit-feedback{
  width: 70vw;
  max-width: none !important;
}