.textLogo1 {
  margin: auto 0;
  cursor: pointer;
}

.learnMore {
  margin-right: 10px !important;
  color: white !important;
  z-index: 1299;
  position: fixed !important;
  top: 13px;
  right: 15%;
}

@media only screen and (min-width: 1700px) {
  .learnMore {
    right: 17%;
  }
}
