.tenantListContainer {
 position: fixed;
 //z-index should be lower than that of modal and higher than header component
 //Modal and Header components have z-index of 1300 and 1298 respectively
 z-index: 1299;
 display: flex;
 flex-direction: column;
 top:16px;
 right: 27%;
 font-family: Proxima Nova, Nunito Sans, sans-serif;
 border-radius: 4px;
}

.selectedTenant{
  display: flex;
  align-items: center;
  color:white;
  align-self: flex-end;
  cursor: pointer;

}
.tenantText{
  padding : 0 4px;
}
.optionsList{
  background: white;
  padding:12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  list-style-type: none;
  width: 250px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.tenantListItem{
  display: flex;
  align-items: center;
  padding: 4px;  
  cursor: pointer;
  color: #0768FD;
}
.tenantListItem>*:last-child {
  margin-left:auto;
}
.tenantListItem:hover{
  background-color: #f3f9ff;
}
.tenantListItem:hover .makeDefaultText{
  display: block;
}
.makeDefaultText{  
  font-size:10px;
  display:none;
}
.makeDefaultText:hover {
  text-decoration: underline;
}
.tenantOptions{
  padding : 0 4px;
  color:black;
}