

.optionsList{
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 2rem;

}
.tenantListItem{
  display: flex;
  align-items: center;
  padding: 4px;  
  cursor: pointer;
  color: #0768FD;
}

.tenantOptions{
  padding : 0 4px;
  color:black;
}

.bottomSpacing{
  margin-bottom: 2rem;
}