.action {
  text-align: right;
  margin-top: 2rem;

  button:first-child {
    margin-right: 0.625rem;
  }
}

.createProjectForm {
  min-width: 530px;
}

.createProjectBtn {
  margin-left: 'auto';
  width: '145px';
}

.readOnlySection {
  margin: 1rem 0 0.5rem 0;
  .readOnly {
    text-indent: 0.5rem;
    color: #999999;
  }
}

.confirmationModal {
  max-width: 440px !important;
}

.spacer {
  margin: 1rem 0 0.5rem 0;
}
