.container {
  .heading {
    font-weight: bold;
    font-size: 22px;
    max-width: 300px;
    max-height: 24px;
    color: #000000;
  }

  .subHeading {
    margin-top: 25px;
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: bold;
    width: 500px;
    height: 12px;
    color: #297dfd;
  }

  p {
    margin-top: 15px;
    margin-bottom: 25px;
    font-size: 14px;
    max-width: 90%;
    line-height: 1.6;
  }

  .supportSection {
    margin-top: 4%;
    margin-left: -2%;
  }
}

.footer {
  font-size: 10px;
  width: 100%;
  bottom: 15px;
  position: absolute;
  left: 0;
}
