.mainContent {
  margin-top: 65px;
  flex-grow: 1;
  width: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  header {
    //relative lower z-index is chosen based on default z-index of apollo modal which is 1300
    z-index: 1298 !important;
  }
}

.sessionResults {
  overflow-x: auto;
  max-width: 100%;
}

.loaderRoot {
  z-index: 99999 !important;
}

div[class^='MuiFormGroup-root'] {
  display: flex !important;
  & > label {
    align-items: center !important;
    & > span:nth-child(2) {
      margin-top: 5px;
    }
  }
  & > div > label {
    & > span:nth-child(2) {
      margin-top: 5px;
    }
  }
}

div[class^='MuiInputBase-root'] {
  & > input:disabled {
    background-color: #f1f1f1f0 !important;
  }
}

div[class^='MuiFormControl-root'] {
  & > label {
    white-space: normal !important;
    word-break: break-word !important;
  }
}
