.welcomeContainer {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  max-width: 100%;
  max-height: 100vh;
  min-width: 680px;
  overflow: hidden;
  .imageContainer {
    flex: 0 0 50%;
    text-align: right;
    .image {
      height: 100vh;
      width: 57vw;
    }
  }

  .loginContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin: 0% 0% 0% 5%;
  }
}
